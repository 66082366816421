// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Genos:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.genos-test {
  font-family: "Genos", sans-serif;
  /* font-optical-sizing: auto; */
  font-weight: 100;
  font-style: normal;
}

.title {
  font-size: 10rem;
  padding: 0;
  margin: 0;
  color: #010B13;
}

.subtitle {
  font-size: 4rem;
  padding-top: 0;
  margin: 0;
  color: #010B13;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1200px) {
  .title {
    font-size: 8rem;
  }
  .subtitle {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 820px) {
  .title {
    font-size: 6rem;
  }
  .subtitle {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 650px) {
  .title {
    font-size: 4rem;
  }
  .subtitle {
    font-size: 1.8rem;
  }
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color:#E9E0D3;
}

/* Remove or comment out these conflicting styles */
/*
.App h1 {
  font-size: 10rem;
  padding: 0;
  margin: 0;
}

.App h2 {
  padding-top: 0;
  font-size: 4rem;
  margin: 0;
}
*/
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;0CAC0C;;AAE1C;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,SAAS;EACT,cAAc;AAChB;;AAEA,qCAAqC;AACrC;EACE;IACE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA,mDAAmD;AACnD;;;;;;;;;;;;CAYC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Genos:ital,wght@0,100..900;1,100..900&display=swap');\n\n/* // <uniquifier>: Use a unique and descriptive class name\n// <weight>: Use a value from 100 to 900 */\n\n.genos-test {\n  font-family: \"Genos\", sans-serif;\n  /* font-optical-sizing: auto; */\n  font-weight: 100;\n  font-style: normal;\n}\n\n.title {\n  font-size: 10rem;\n  padding: 0;\n  margin: 0;\n  color: #010B13;\n}\n\n.subtitle {\n  font-size: 4rem;\n  padding-top: 0;\n  margin: 0;\n  color: #010B13;\n}\n\n/* Media queries for responsiveness */\n@media screen and (max-width: 1200px) {\n  .title {\n    font-size: 8rem;\n  }\n  .subtitle {\n    font-size: 3.2rem;\n  }\n}\n\n@media screen and (max-width: 820px) {\n  .title {\n    font-size: 6rem;\n  }\n  .subtitle {\n    font-size: 2.4rem;\n  }\n}\n\n@media screen and (max-width: 650px) {\n  .title {\n    font-size: 4rem;\n  }\n  .subtitle {\n    font-size: 1.8rem;\n  }\n}\n\n.App {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color:#E9E0D3;\n}\n\n/* Remove or comment out these conflicting styles */\n/*\n.App h1 {\n  font-size: 10rem;\n  padding: 0;\n  margin: 0;\n}\n\n.App h2 {\n  padding-top: 0;\n  font-size: 4rem;\n  margin: 0;\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
