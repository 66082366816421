import './App.css';

function App() {
  return (
    <div className="App">
     
        <h1 className="genos-test title">
          {"<indieHacker/>"}
        </h1>
        <h2 className="genos-test subtitle">build. ship. sleep. repeat.</h2>
        
    </div>
  );
}

export default App;
